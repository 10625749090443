import React, { useState, useEffect } from "react"
import {GetTeamDetails} from "../../queries/common_use_query";

// Styles
import "./Negdetails.scss"

const Negdetails = (props) => {
  const [ details, setGetDetails ] = useState({});
  const { loading, error, data } = GetTeamDetails(props?.Negoslug);
  
  useEffect(()=>{
          data && data.teams.length > 0 && setGetDetails(data.teams[0])
      },[data])
    
  return (
    <div>
      <div className="neg_details">
           <div class="client-details-wrapper">
          <div class="client-profile-picture">
              <img src={details?.image?.url} alt={details.Title}  />
          </div>
          <div>
          <div class="client-details">
              <div class="client-name">{details.Title}</div>
              <div class="client-place">{details.Designation}</div>
              <div class="date">{details.Phone_Number} | <a href="">Contact us</a></div>
          </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default Negdetails
